import { IBusinessAdmin } from "./businessAdmin.interface";
import { PartnerBucketGroups } from "./partnerDeal.interface";

export interface IBusinessFull {
  _id: string;
  businessName: string;
  businessTradeName: string;
  catalog: string;
  transactionTags: string;
  rootUser: IBusinessAdmin;
  industry: string;
  businessType: BusinessType;
  payoutOptions: {
    schedule: "daily" | "weekly-wed" | "weekly-thur" | "weekly-fri" | "monthly";
  };
  address: {
    street: string;
    houseNumber: string;
    houseNumberSuffix: string;
    postcode: string;
    city: string;
    country: string;
  };
  merchantCategoryCode: number;
  transactionInformation: {
    averageMonthlyVolume: number;
    averageTimeBetweenPurchaseAndDeliveryInDays: number;
    expectedAverageMonthlyNumberOfTransactions: number;
    expectedAverageTransactionAmount: number;
    expectedMaximumTransactionAmount: number;
  };
  merchantServiceChargeFees: {
    name: string;
    value: number;
    valueType: "Fixed" | "Percentage";
  }[];
  // bankAccountHolderName: string;
  bankAccountIban: string;
  bankAccountNumber: string;
  bankName: string;
  bic: string;
  bankAddress: {
    street: string;
    houseNumber: string;
    postcode: string;
    city: string;
    country: string;
  };
  referralCode: string;
  referrerCode: string;
  status: BusinessStatus;
  phosTerminalToken: string;
  phosMerchantToken: string;
  phosUserToken: string;
  merchandId: string;
  mid: string;
  agreementId: string;
  terminalId: string;
  vat: number;
  createdAt: string;
  updatedAt: string;
  businessRegistrationNumber: string;
  persons: BusinessPerson[];
  onboardingError: {
    type: string;
    message: string;
  };
  mccComments?: string;
  generalComments?: string;
  incorporationDate?: string;
  complianceContact: ComplianceContact;
  salesRepresentative: string;
  salesRepresentativeExpDate: string;
  salesRepresentative2: string;
  salesRepresentative2ExpDate: string;
  salesBusinessDeveloper: string;
  salesBusinessDeveloperExpDate: string;
  onboardingFlags: IOnboardingFlag[];
  yazaraMerchantId: string;
  yazaraTerminals: IYazaraTerminal[];
  description?: string;
  settings: IBusinessSettings;
  partnerId?: string;
  partnership?: string;
  partnerPurchPriceProfileId: string;
  softwareVendorPricing?: PartnerBucketGroups;
  devices: IDevice[];
  verificationDate: string;
}

export enum BusinessSettingsEndOfShiftCalculationType {
  PerBusiness = "perBusiness",
  PerDevice = "perDevice",
  PerUser = "perUser",
}

export interface IBusinessSettings {
  payments: {
    enableCashPayments: boolean;
    enableDiscounts: boolean;
    enableTips: boolean;
    optionalTipping: boolean; //default true
    tipPercentagePresets: {
      percentage1: number;
      percentage2: number;
      percentage3: number;
      percentage4: number;
    };
    enableSkipTags: boolean;
    decimalFirstAmountEntry: boolean;
  };
  endOfShift: {
    enableEndOfShift: boolean; // if this is disabled, we don't show this feature to the merchant
    calculationType: BusinessSettingsEndOfShiftCalculationType; //calculation type

    calculateVolumeFromLastShift: boolean; // if this is true, the shift will always calculate the volume from the start of the day, if it is false it will calculate the volume from the last shift.
    requireEndOfShiftCode: boolean;
    endOfShiftCode: string; // 4-digit code for ending the shift
    shiftReportDefaultEmail: string;
  };
  endOfDay: {
    hour: number;
    minute: number;
  };
  receipt: {
    logoUrl: string;
    showAddress: boolean;
    extraText: string;
    qrUrl: string;
  };
  reports: {
    sendList: string[];
    types: {
      endOfDaySalesOverviewReport: IReportSettings;
      dailyPayoutReport: IReportSettings;
      monthlyAccountingReport: IReportSettings;
    };
  };
}

export type IReportSettings = {
  isEnabled: boolean;
  scheduleType: ScheduleType;
};

export type ScheduleType =
  | "daily-end-of-day"
  | "daily-day-after"
  | "monthly-start-of-next-month";

export interface IYazaraTerminal {
  DID: string;
  TID: string;
  MID: string;
  createdAt: string;
  activationCode: string;
  isRandomDeviceId?: boolean;
}

export interface IOnboardingFlag {
  type:
    | "BUSINESS_TYPE_OTHER"
    | "PERSON_MISSING_ROLE"
    | "MISSING_PERSON"
    | "CUSTOM_PERSON"
    | "BUSINESS_ADDRESS_EDIT"
    | "PAYOUT_SCHEDULE_CHANGE"
    | "IDENTIFICATION_CHECK_NAME_MISMATCH"
    | "IBAN_CHECK_MISMATCH"
    | "IBAN_CHECK_SERVER_DOWN";
  message: string;
  isResolved: boolean;
}
export enum ComplianceStatus {
  NotContacted = "Not Contacted",
  ReachedOut = "Reached Out",
  InProgress = "In Progress",
  Completed = "Completed",
}
export interface ComplianceContact {
  status: ComplianceStatus;
  dateOfContact: Date;
  teamMember: string;
  notes: string;
}

export type IBusinessFullQuery = IBusinessFull & {
  // rootUserEmail: string;
  // rootUserName: string;
  complianceStatus: ComplianceStatus;
  name: string;
};

export type BusinessStatus =
  | "pendingSubmission"
  | "pendingVerification"
  | "verified"
  | "unverified"
  | "inactivated";

export type BusinessPerson = {
  address: IAddress;
  dateOfBirth: string;
  email: string;
  firstName: string;
  lastName: string;
  identificationAttachment: {
    fileName: string;
    bucketName: string; //url
  };
  ssn: string;
  phone: { number: string; countryCode: string };
  roles: BusinessRole[];
  isCustom: boolean;
};

export interface IAddress {
  street: string;
  houseNumber: string;
  houseNumberSuffix?: string;
  postcode: string;
  city: string;
  country: string;
}

export type BusinessRole =
  | "PrimaryContact"
  | "Director"
  | "AuthorizedSignatory"
  | "BoardOfDirectors"
  | "BeneficialOwner"
  | "VotingControl"
  | "PersonShareOwner";

export type BusinessType =
  | "soleTrader"
  | "publicLimitedCompany"
  | "privateLimitedCompany"
  | "partnership";

export type DeviceType = "Android" | "IOS" | "Sunmi" | "Pax";

export interface IDevice {
  type: DeviceType;
  deviceId: string;
  model: string;
  shortId: string;
  manufacturer: string;
  lastOnline: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  buildName: string;
  buildNumber: string;
}
