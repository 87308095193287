import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Tag,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { selectCurrentBusiness } from "../../../businessesSlice";
import { useEffect, useMemo, useState } from "react";
import styles from "../BusinessEditor.module.css";
import {
  getPartnersThunk,
  selectPartners,
} from "../../../../partners/partnersSlice";
import Loader from "../../../../common/loader/Loader";
import {
  IBucketGroups,
  IPartner,
  IPurchPriceProfile,
} from "../../../../../interfaces/partner.interface";
import BucketGroupValue from "../../../../partner-deals/components/partner-deals-table/subcomponents/bucket-group-value/BucketGroupValue";
import { getBucketGroupName } from "../../../utils/getBucketGroupName";
import { valueTypeLabel } from "../../../../partner-deals/components/create-edit-bucket-groups-form/CreateEditBucketGroupsForm";

const bucketLayout = {
  labelCol: { span: 14 },
  wrapperCol: { span: 18 },
};

export default function PartnerISVPricing() {
  const currentBusiness = useAppSelector(selectCurrentBusiness);

  console.log("currentBusiness", currentBusiness?.partnerId);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, [currentBusiness]);

  let partner: Partial<IPartner> =
    currentBusiness?.partnerId as Partial<IPartner>;

  const PricingFormItem = (
    type: "domesticDebitCards" | "european" | "internationalAndCommercial"
  ) => {
    let label =
      type === "domesticDebitCards"
        ? "Domestic Debit Cards"
        : type === "european"
        ? "E.E.A Cards"
        : "Non E.E.A & Corporate Cards";
    let toolTipText =
      type === "domesticDebitCards"
        ? "Includes domestic debit cards only."
        : type === "european"
        ? "Includes all European debit and credit cards that are not commerical or international as well as all domestic credit cards."
        : "Includes all commerical & international debit and credit cards.";

    let max = partner?.softwareVendorPricing?.[type]?.value;
    return (
      <>
        <Form.Item
          label={label}
          name={["softwareVendorPricing", type, "value"]}
          labelCol={{ ...bucketLayout.labelCol }}
          labelAlign="left"
          tooltip={toolTipText}
          help={max ? `Max Total Price ${max}` : ""}
          className={styles.bucket_group_form_item}
        >
          <InputNumber
            controls={false}
            className={styles.bucket_value_input}
            min={0}
            max={max ? max : Number.POSITIVE_INFINITY}
            style={{ width: "140px" }}
            addonAfter={
              partner?.softwareVendorPricing?.[type]?.valueType
                ? valueTypeLabel(
                    partner?.softwareVendorPricing?.[type]?.valueType
                  )
                : type === "domesticDebitCards"
                ? "Fixed"
                : "%"
            }
          />
        </Form.Item>

        <Form.Item hidden name={["softwareVendorPricing", type, "valueType"]}>
          <Input />
        </Form.Item>
        {partner?.softwareVendorPricing?.[type]?.valueType && (
          <Form.Item hidden name={["softwareVendorPricing", type, "valueType"]}>
            <Input />
          </Form.Item>
        )}
        {!partner?.softwareVendorPricing?.[type]?.valueType && (
          <Form.Item
            hidden
            name={["softwareVendorPricing", type, "valueType"]}
            initialValue={
              type === "domesticDebitCards" ? "Fixed" : "Percentage"
            }
          >
            <Input />
          </Form.Item>
        )}
      </>
    );
  };

  return (
    <>
      {!isLoading && (
        <>
          <h3>Override Partner ISV Fees</h3>
          <h5>Only applicable to merchants with a partner</h5>
          <br />
          {PricingFormItem("domesticDebitCards")}
          {PricingFormItem("european")}
          {PricingFormItem("internationalAndCommercial")}
        </>
      )}

      {isLoading && <Loader text="Getting ISV of partner" />}
    </>
  );
}
