import { Button, Divider, Form, Input, Modal, Radio, Tabs } from "antd";

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  ComplianceStatus,
  IBusinessFull,
} from "../../../../interfaces/businessFull.interface";
import {
  editBusinessThunk,
  editBusinessUserThunk,
  selectCurrentBusiness,
} from "../../businessesSlice";
import BusinessUsersEditor from "../Business-Users-Editor-Modal/BusinessUsersEditor";
import {
  AppstoreOutlined,
  UserSwitchOutlined,
  CommentOutlined,
  SendOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { IBusinessUser } from "../../../../interfaces/businessUser.interface";
import CompanyAddressEditor from "./components/CompanyAddressEditor";
import BankAccountEditor from "./components/BankAccountEditor";
import PersonsEditor from "./components/PersonsEditor";

import CompanyProfileEditor from "./components/CompanyProfileEditor";
import SettingsEditor from "./components/SettingsEditor";
import PayoutDetailsEditor from "./components/PayoutDetailsEditor";
import MerchantServiceFeesEditor from "./components/MerchantServiceFeesEditor";
import FlagEditor from "./components/FlagEditor";
import PartnerMapper from "./components/PartnerMapper";
import PartnerISVPricing from "./components/PartnerISVPricing";
const { TextArea } = Input;

interface IPropsBusinessEditorModel {
  visible: boolean;
  setVisible: any;
}

export default function BusinessEditor({
  visible,
  setVisible,
}: IPropsBusinessEditorModel) {
  const [loading, setLoading] = useState(false);
  const currentBusiness = useAppSelector(selectCurrentBusiness);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      businessName: currentBusiness.businessName,
      businessTradeName: currentBusiness.businessTradeName,
      businessRegistrationNumber: currentBusiness.businessRegistrationNumber,
      merchantServiceChargeFees: currentBusiness.merchantServiceChargeFees,
      vat: currentBusiness.vat,
      merchantCategoryCode: currentBusiness.merchantCategoryCode,
      industry: currentBusiness.industry,
      name: currentBusiness.rootUser?.name,
      businessType: currentBusiness.businessType,
      payoutOptions: currentBusiness.payoutOptions
        ? currentBusiness.payoutOptions
        : { schedule: "daily" },
      bankAccountIban: currentBusiness.bankAccountIban,
      bic: currentBusiness.bic,
      bankAccountNumber: currentBusiness.bankAccountNumber,
      mccComments: currentBusiness.mccComments,
      generalComments: currentBusiness.generalComments,
      complianceContact: { ...currentBusiness.complianceContact },
      bankAddress: currentBusiness.bankAddress,
      bankName: currentBusiness.bankName,
      address: currentBusiness.address,
      onboardingFlags: currentBusiness.onboardingFlags,
      description: currentBusiness.description,
      //@ts-ignore
      partnerId: currentBusiness.partnerId?._id as string,
      partnerPurchPriceProfileId: currentBusiness.partnerPurchPriceProfileId,
      softwareVendorPricing: currentBusiness.softwareVendorPricing,
      settings: {
        ...currentBusiness?.settings,
        reports: {
          ...currentBusiness?.settings?.reports,
          sendList:
            currentBusiness.settings?.reports?.sendList?.length > 0
              ? currentBusiness.settings?.reports?.sendList
              : [currentBusiness.rootUser?.email],
        },
      },
      persons: currentBusiness?.persons?.map((p) => ({
        ...p,
        dateOfBirth: p.dateOfBirth ? moment(p.dateOfBirth) : null,
      })),
    });

    // return () => {
    //   form.resetFields();
    // };
  }, [currentBusiness]);
  const handleSubmit = async (values: Partial<IBusinessFull>) => {
    setLoading(true);
    values.persons = values.persons?.map((p) => ({
      ...p,
      dateOfBirth: (p.dateOfBirth as unknown as moment.Moment).format(),
    }));
    // return;
    await dispatch(
      editBusinessThunk({ _id: currentBusiness._id, updatedFields: values })
    );

    if (values && values?.persons && values?.persons[0]) {
      let updatedFields = {} as Partial<IBusinessUser>;
      let person1 = values?.persons[0];
      if (person1.address) updatedFields.address = person1.address;
      if (person1.dateOfBirth) updatedFields.dateofbirth = person1.dateOfBirth;
      if (person1.firstName) updatedFields.firstName = person1.firstName;
      if (person1.lastName) updatedFields.lastName = person1.lastName;
      if (person1.phone) updatedFields.phone = person1.phone;

      if (values.businessRegistrationNumber)
        values.businessRegistrationNumber =
          values.businessRegistrationNumber.trim();

      await dispatch(
        editBusinessUserThunk({
          _id: currentBusiness.rootUser._id,
          updatedFields,
        })
      );
    }
    setLoading(false);
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
    // form.resetFields();
  };

  const mccAndIndustryFieldLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  return (
    <Modal
      visible={visible}
      title={"Update " + currentBusiness?.businessName}
      onCancel={handleCancel}
      footer={null}
      width={650}
    >
      <Tabs type="card">
        <Tabs.TabPane
          tab={
            <>
              <AppstoreOutlined /> Business Details
            </>
          }
          key="businessDetails"
        >
          <Form onFinish={handleSubmit} form={form}>
            <Tabs tabPosition="left">
              <Tabs.TabPane tab="Company Profile" key="item-121">
                <CompanyProfileEditor />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Company Address" key="item-1212">
                <CompanyAddressEditor />
              </Tabs.TabPane>
              <Tabs.TabPane tab="MCC & Industry" key="item-1a">
                <Form.Item
                  label="MCC"
                  name="merchantCategoryCode"
                  labelCol={mccAndIndustryFieldLayout.labelCol}
                  wrapperCol={mccAndIndustryFieldLayout.wrapperCol}
                  labelAlign="left"
                >
                  <Input max={4} />
                </Form.Item>

                <Form.Item
                  label="Industry"
                  name="industry"
                  labelCol={mccAndIndustryFieldLayout.labelCol}
                  wrapperCol={mccAndIndustryFieldLayout.wrapperCol}
                  labelAlign="left"
                >
                  <Input></Input>
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  labelCol={mccAndIndustryFieldLayout.labelCol}
                  wrapperCol={mccAndIndustryFieldLayout.wrapperCol}
                  labelAlign="left"
                >
                  <TextArea
                    showCount
                    style={{
                      marginTop: "5px",
                      opacity: "0.94",
                    }}
                    placeholder="Add description of what business does here"
                    autoSize={{ minRows: 2, maxRows: 10 }}
                  />
                </Form.Item>
                <Form.Item name="mccComments" labelAlign="left">
                  <TextArea
                    showCount
                    style={{
                      marginTop: "5px",
                      opacity: "0.94",
                    }}
                    placeholder="Add comments here"
                    autoSize={{ minRows: 3, maxRows: 10 }}
                  />
                </Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Merchant Service Fees" key="item-2">
                <MerchantServiceFeesEditor />
              </Tabs.TabPane>

              <Tabs.TabPane tab="Payout Details" key="item-5">
                <PayoutDetailsEditor />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Bank Account" key="item-6">
                <BankAccountEditor />
              </Tabs.TabPane>

              <Tabs.TabPane tab="Settings" key="item-5a">
                <SettingsEditor currentBusiness={currentBusiness} form={form} />
              </Tabs.TabPane>

              <Tabs.TabPane tab="Persons" key="item-7">
                <PersonsEditor form={form} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Flags" key="item-102">
                <FlagEditor form={form} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Partner Mapper" key="item-partner">
                <PartnerMapper />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Partner ISV" key="item-partner-isv">
                <PartnerISVPricing />
              </Tabs.TabPane>
            </Tabs>
            <Form.Item>
              <Button
                style={{ float: "right" }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <>
              <UserSwitchOutlined /> Users
            </>
          }
          key="users"
        >
          <BusinessUsersEditor></BusinessUsersEditor>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <>
              <CommentOutlined /> General Comments
            </>
          }
          key="generalComments"
        >
          <Form onFinish={handleSubmit} form={form}>
            <Form.Item name="generalComments" labelAlign="left">
              <TextArea
                showCount
                style={{
                  marginTop: "10px",
                  opacity: "0.94",
                }}
                placeholder="Add comments here"
                autoSize={{ minRows: 8, maxRows: 12 }}
                maxLength={500}
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ float: "right", marginTop: "20px" }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <>
              <SendOutlined />
              Outreach
            </>
          }
          key="complianceContact"
        >
          <Form onFinish={handleSubmit} form={form}>
            <Form.Item name={["complianceContact", "status"]} labelAlign="left">
              <Radio.Group defaultValue={ComplianceStatus.NotContacted}>
                <Radio value={ComplianceStatus.NotContacted}>
                  {ComplianceStatus.NotContacted}
                </Radio>
                <Radio value={ComplianceStatus.ReachedOut}>
                  {ComplianceStatus.ReachedOut}
                </Radio>
                <Radio value={ComplianceStatus.Completed}>
                  {ComplianceStatus.Completed}
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item name={["complianceContact", "notes"]} labelAlign="left">
              <TextArea
                showCount
                style={{
                  marginTop: "10px",
                  opacity: "0.94",
                }}
                placeholder="Add comments here"
                autoSize={{ minRows: 8, maxRows: 12 }}
                maxLength={500}
              />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ float: "right", marginTop: "20px" }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
}
